import "./styles.css";

export default function App() {
  const contact = "hiatvonvdotdev".replace("at", "@").replace("dot", ".");

  return (
    <div className="container">
      <div className="card">
        <div className="head">></div>
        <div className="sub">
          <div>
            <span className="name">Von Villamor</span> <br />
            Technical Lead • Software Engineer
            <br />
            <div className="divider" />
            <i class="devicon-python-plain"></i>
            <i class="devicon-go-plain"></i>
            <i class="devicon-react-original"></i>
            <i class="devicon-javascript-plain"></i>
            <i class="devicon-amazonwebservices-original"></i>
            <i class="devicon-googlecloud-plain"></i>
            <i class="devicon-heroku-original"></i>
            <i class="devicon-nodejs-plain"></i>
            <i class="devicon-mongodb-plain"></i>
            <i class="devicon-rust-plain"></i>
          </div>
          <div class="links">
            <a href="https://github.com/nokusukun">
              <i class="devicon-github-original"></i>
            </a>
            &nbsp;
            <a href="https://www.linkedin.com/in/von-v-497059174/">
              <i class="devicon-linkedin-plain"></i>
            </a>
            &nbsp;
            <a href={"mailto:" + contact} class="colorize">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 -3 24 24"
              >
                <path d="M0 3v18h24v-18h-24zm6.623 7.929l-4.623 5.712v-9.458l4.623 3.746zm-4.141-5.929h19.035l-9.517 7.713-9.518-7.713zm5.694 7.188l3.824 3.099 3.83-3.104 5.612 6.817h-18.779l5.513-6.812zm9.208-1.264l4.616-3.741v9.348l-4.616-5.607z" />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
